export const MONTHS = [
  { value: '01', label: 'January' },
  { value: '02', label: 'February' },
  { value: '03', label: 'March' },
  { value: '04', label: 'April' },
  { value: '05', label: 'May' },
  { value: '06', label: 'June' },
  { value: '07', label: 'July' },
  { value: '08', label: 'August' },
  { value: '09', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' },
];

// TODO: deprecated, remove
export const STATES = [
  { value: 'AK', label: 'Alaska' },
  { value: 'AL', label: 'Alabama' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'AS', label: 'American Samoa' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DC', label: 'District of Columbia' },
  { value: 'DE', label: 'Delaware' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'GU', label: 'Guam' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'IA', label: 'Iowa' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MD', label: 'Maryland' },
  { value: 'ME', label: 'Maine' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MT', label: 'Montana' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NY', label: 'New York' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VA', label: 'Virginia' },
  { value: 'VI', label: 'Virgin Islands' },
  { value: 'VT', label: 'Vermont' },
  { value: 'WA', label: 'Washington' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WY', label: 'Wyoming' },
  { value: 'UM', label: 'United States Minor Outlying Islands' },
];

// export const COUNTRIES = [{ label: 'United States', value: 'US' }];
export const COUNTRIES = [
  { label: 'United States of America', value: 'US' },
  { label: 'Afghanistan', value: 'AF' },
  { label: 'Åland Islands', value: 'AX' },
  { label: 'Albania', value: 'AL' },
  { label: 'Algeria', value: 'DZ' },
  { label: 'American Samoa', value: 'AS' },
  { label: 'Andorra', value: 'AD' },
  { label: 'Angola', value: 'AO' },
  { label: 'Anguilla', value: 'AI' },
  { label: 'Antarctica', value: 'AQ' },
  { label: 'Antigua and Barbuda', value: 'AG' },
  { label: 'Argentina', value: 'AR' },
  { label: 'Armenia', value: 'AM' },
  { label: 'Aruba', value: 'AW' },
  { label: 'Australia', value: 'AU' },
  { label: 'Austria', value: 'AT' },
  { label: 'Azerbaijan', value: 'AZ' },
  { label: 'Bahamas', value: 'BS' },
  { label: 'Bahrain', value: 'BH' },
  { label: 'Bangladesh', value: 'BD' },
  { label: 'Barbados', value: 'BB' },
  { label: 'Belarus', value: 'BY' },
  { label: 'Belgium', value: 'BE' },
  { label: 'Belize', value: 'BZ' },
  { label: 'Benin', value: 'BJ' },
  { label: 'Bermuda', value: 'BM' },
  { label: 'Bhutan', value: 'BT' },
  { label: 'Bolivia', value: 'BO' },
  { label: 'Bosnia and Herzegovina', value: 'BA' },
  { label: 'Botswana', value: 'BW' },
  { label: 'Bouvet Island', value: 'BV' },
  { label: 'Brazil', value: 'BR' },
  { label: 'British Indian Ocean Territory', value: 'IO' },
  { label: 'Brunei Darussalam', value: 'BN' },
  { label: 'Bulgaria', value: 'BG' },
  { label: 'Burkina Faso', value: 'BF' },
  { label: 'Burundi', value: 'BI' },
  { label: 'Cambodia', value: 'KH' },
  { label: 'Cameroon', value: 'CM' },
  { label: 'Canada', value: 'CA' },
  { label: 'Cape Verde', value: 'CV' },
  { label: 'Cayman Islands', value: 'KY' },
  { label: 'Central African Republic', value: 'CF' },
  { label: 'Chad', value: 'TD' },
  { label: 'Chile', value: 'CL' },
  { label: 'China', value: 'CN' },
  { label: 'Christmas Island', value: 'CX' },
  { label: 'Cocos (Keeling) Islands', value: 'CC' },
  { label: 'Colombia', value: 'CO' },
  { label: 'Comoros', value: 'KM' },
  { label: 'Congo', value: 'CG' },
  { label: 'Congo, The Democratic Republic of the', value: 'CD' },
  { label: 'Cook Islands', value: 'CK' },
  { label: 'Costa Rica', value: 'CR' },
  { label: "Cote D'Ivoire", value: 'CI' },
  { label: 'Croatia', value: 'HR' },
  { label: 'Cuba', value: 'CU' },
  { label: 'Cyprus', value: 'CY' },
  { label: 'Czech Republic', value: 'CZ' },
  { label: 'Denmark', value: 'DK' },
  { label: 'Djibouti', value: 'DJ' },
  { label: 'Dominica', value: 'DM' },
  { label: 'Dominican Republic', value: 'DO' },
  { label: 'Ecuador', value: 'EC' },
  { label: 'Egypt', value: 'EG' },
  { label: 'El Salvador', value: 'SV' },
  { label: 'Equatorial Guinea', value: 'GQ' },
  { label: 'Eritrea', value: 'ER' },
  { label: 'Estonia', value: 'EE' },
  { label: 'Ethiopia', value: 'ET' },
  { label: 'Falkland Islands (Malvinas)', value: 'FK' },
  { label: 'Faroe Islands', value: 'FO' },
  { label: 'Fiji', value: 'FJ' },
  { label: 'Finland', value: 'FI' },
  { label: 'France', value: 'FR' },
  { label: 'French Guiana', value: 'GF' },
  { label: 'French Polynesia', value: 'PF' },
  { label: 'French Southern Territories', value: 'TF' },
  { label: 'Gabon', value: 'GA' },
  { label: 'Gambia', value: 'GM' },
  { label: 'Georgia', value: 'GE' },
  { label: 'Germany', value: 'DE' },
  { label: 'Ghana', value: 'GH' },
  { label: 'Gibraltar', value: 'GI' },
  { label: 'Greece', value: 'GR' },
  { label: 'Greenland', value: 'GL' },
  { label: 'Grenada', value: 'GD' },
  { label: 'Guadeloupe', value: 'GP' },
  { label: 'Guam', value: 'GU' },
  { label: 'Guatemala', value: 'GT' },
  { label: 'Guernsey', value: 'GG' },
  { label: 'Guinea', value: 'GN' },
  { label: 'Guinea-Bissau', value: 'GW' },
  { label: 'Guyana', value: 'GY' },
  { label: 'Haiti', value: 'HT' },
  { label: 'Heard Island and Mcdonald Islands', value: 'HM' },
  { label: 'Holy See (Vatican City State)', value: 'VA' },
  { label: 'Honduras', value: 'HN' },
  { label: 'Hong Kong', value: 'HK' },
  { label: 'Hungary', value: 'HU' },
  { label: 'Iceland', value: 'IS' },
  { label: 'India', value: 'IN' },
  { label: 'Indonesia', value: 'ID' },
  { label: 'Iran, Islamic Republic Of', value: 'IR' },
  { label: 'Iraq', value: 'IQ' },
  { label: 'Ireland', value: 'IE' },
  { label: 'Isle of Man', value: 'IM' },
  { label: 'Israel', value: 'IL' },
  { label: 'Italy', value: 'IT' },
  { label: 'Jamaica', value: 'JM' },
  { label: 'Japan', value: 'JP' },
  { label: 'Jersey', value: 'JE' },
  { label: 'Jordan', value: 'JO' },
  { label: 'Kazakhstan', value: 'KZ' },
  { label: 'Kenya', value: 'KE' },
  { label: 'Kiribati', value: 'KI' },
  { label: "Korea, Democratic People's Republic of", value: 'KP' },
  { label: 'Korea, Republic of', value: 'KR' },
  { label: 'Kuwait', value: 'KW' },
  { label: 'Kyrgyzstan', value: 'KG' },
  { label: "Lao People's Democratic Republic", value: 'LA' },
  { label: 'Latvia', value: 'LV' },
  { label: 'Lebanon', value: 'LB' },
  { label: 'Lesotho', value: 'LS' },
  { label: 'Liberia', value: 'LR' },
  { label: 'Libyan Arab Jamahiriya', value: 'LY' },
  { label: 'Liechtenstein', value: 'LI' },
  { label: 'Lithuania', value: 'LT' },
  { label: 'Luxembourg', value: 'LU' },
  { label: 'Macao', value: 'MO' },
  { label: 'Macedonia, The Former Yugoslav Republic of', value: 'MK' },
  { label: 'Madagascar', value: 'MG' },
  { label: 'Malawi', value: 'MW' },
  { label: 'Malaysia', value: 'MY' },
  { label: 'Maldives', value: 'MV' },
  { label: 'Mali', value: 'ML' },
  { label: 'Malta', value: 'MT' },
  { label: 'Marshall Islands', value: 'MH' },
  { label: 'Martinique', value: 'MQ' },
  { label: 'Mauritania', value: 'MR' },
  { label: 'Mauritius', value: 'MU' },
  { label: 'Mayotte', value: 'YT' },
  { label: 'Mexico', value: 'MX' },
  { label: 'Micronesia, Federated States of', value: 'FM' },
  { label: 'Moldova, Republic of', value: 'MD' },
  { label: 'Monaco', value: 'MC' },
  { label: 'Mongolia', value: 'MN' },
  { label: 'Montenegro', value: 'ME' },
  { label: 'Montserrat', value: 'MS' },
  { label: 'Morocco', value: 'MA' },
  { label: 'Mozambique', value: 'MZ' },
  { label: 'Myanmar', value: 'MM' },
  { label: 'Namibia', value: 'NA' },
  { label: 'Nauru', value: 'NR' },
  { label: 'Nepal', value: 'NP' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'Netherlands Antilles', value: 'AN' },
  { label: 'New Caledonia', value: 'NC' },
  { label: 'New Zealand', value: 'NZ' },
  { label: 'Nicaragua', value: 'NI' },
  { label: 'Niger', value: 'NE' },
  { label: 'Nigeria', value: 'NG' },
  { label: 'Niue', value: 'NU' },
  { label: 'Norfolk Island', value: 'NF' },
  { label: 'Northern Mariana Islands', value: 'MP' },
  { label: 'Norway', value: 'NO' },
  { label: 'Oman', value: 'OM' },
  { label: 'Pakistan', value: 'PK' },
  { label: 'Palau', value: 'PW' },
  { label: 'Palestinian Territory, Occupied', value: 'PS' },
  { label: 'Panama', value: 'PA' },
  { label: 'Papua New Guinea', value: 'PG' },
  { label: 'Paraguay', value: 'PY' },
  { label: 'Peru', value: 'PE' },
  { label: 'Philippines', value: 'PH' },
  { label: 'Pitcairn', value: 'PN' },
  { label: 'Poland', value: 'PL' },
  { label: 'Portugal', value: 'PT' },
  { label: 'Puerto Rico', value: 'PR' },
  { label: 'Qatar', value: 'QA' },
  { label: 'Reunion', value: 'RE' },
  { label: 'Romania', value: 'RO' },
  { label: 'Russian Federation', value: 'RU' },
  { label: 'RWANDA', value: 'RW' },
  { label: 'Saint Helena', value: 'SH' },
  { label: 'Saint Kitts and Nevis', value: 'KN' },
  { label: 'Saint Lucia', value: 'LC' },
  { label: 'Saint Pierre and Miquelon', value: 'PM' },
  { label: 'Saint Vincent and the Grenadines', value: 'VC' },
  { label: 'Samoa', value: 'WS' },
  { label: 'San Marino', value: 'SM' },
  { label: 'Sao Tome and Principe', value: 'ST' },
  { label: 'Saudi Arabia', value: 'SA' },
  { label: 'Senegal', value: 'SN' },
  { label: 'Serbia', value: 'RS' },
  { label: 'Seychelles', value: 'SC' },
  { label: 'Sierra Leone', value: 'SL' },
  { label: 'Singapore', value: 'SG' },
  { label: 'Slovakia', value: 'SK' },
  { label: 'Slovenia', value: 'SI' },
  { label: 'Solomon Islands', value: 'SB' },
  { label: 'Somalia', value: 'SO' },
  { label: 'South Africa', value: 'ZA' },
  { label: 'South Georgia and the South Sandwich Islands', value: 'GS' },
  { label: 'Spain', value: 'ES' },
  { label: 'Sri Lanka', value: 'LK' },
  { label: 'Sudan', value: 'SD' },
  { label: 'Suriname', value: 'SR' },
  { label: 'Svalbard and Jan Mayen', value: 'SJ' },
  { label: 'Swaziland', value: 'SZ' },
  { label: 'Sweden', value: 'SE' },
  { label: 'Switzerland', value: 'CH' },
  { label: 'Syrian Arab Republic', value: 'SY' },
  { label: 'Taiwan, Province of China', value: 'TW' },
  { label: 'Tajikistan', value: 'TJ' },
  { label: 'Tanzania, United Republic of', value: 'TZ' },
  { label: 'Thailand', value: 'TH' },
  { label: 'Timor-Leste', value: 'TL' },
  { label: 'Togo', value: 'TG' },
  { label: 'Tokelau', value: 'TK' },
  { label: 'Tonga', value: 'TO' },
  { label: 'Trinidad and Tobago', value: 'TT' },
  { label: 'Tunisia', value: 'TN' },
  { label: 'Turkey', value: 'TR' },
  { label: 'Turkmenistan', value: 'TM' },
  { label: 'Turks and Caicos Islands', value: 'TC' },
  { label: 'Tuvalu', value: 'TV' },
  { label: 'Uganda', value: 'UG' },
  { label: 'Ukraine', value: 'UA' },
  { label: 'United Arab Emirates', value: 'AE' },
  { label: 'United Kingdom', value: 'GB' },
  // { label: 'United States', value: 'US' },
  { label: 'United States Minor Outlying Islands', value: 'UM' },
  { label: 'Uruguay', value: 'UY' },
  { label: 'Uzbekistan', value: 'UZ' },
  { label: 'Vanuatu', value: 'VU' },
  { label: 'Venezuela', value: 'VE' },
  { label: 'Viet Nam', value: 'VN' },
  { label: 'Virgin Islands, British', value: 'VG' },
  { label: 'Virgin Islands, U.S.', value: 'VI' },
  { label: 'Wallis and Futuna', value: 'WF' },
  { label: 'Western Sahara', value: 'EH' },
  { label: 'Yemen', value: 'YE' },
  { label: 'Zambia', value: 'ZM' },
  { label: 'Zimbabwe', value: 'ZW' },
];

export const DEGREE_TYPES = [
  { value: 'none', label: 'Did Not Graduate' },
  { value: 'certificate', label: 'Certificate' },
  { value: 'associate', label: "Associate's" },
  { value: 'bachelor', label: "Bachelor's" },
  { value: 'master', label: "Master's" },
  { value: 'doctoral', label: 'Doctoral' },
  { value: 'professional', label: 'Professional' },
];

export const STUDY_FORMATS = [
  { value: 'online', label: 'Online' },
  { value: 'hybrid', label: 'Hybrid' },
  { value: 'campus', label: 'On Campus' },
];

export const SECONDARY_INSTITUTION_TYPES = [
  { value: 'high_school', label: 'High School' },
  { value: 'ged', label: 'GED' },
  { value: 'home_school', label: 'Home School' },
];

export const MILITARY_SCHOOLS = [
  { value: 'air_force', label: 'Air Force - CCAF' },
  { value: 'army', label: 'Joint Services - Army' },
  { value: 'navy', label: 'Joint Services - Navy/Marines' },
  { value: 'coast_guard', label: 'Joint Services - USCG' },
];

export const SUFFIX_VALUES = [
  { value: ' ', label: '' },
  { value: 'junior', label: 'JR' },
  { value: 'senior', label: 'SR' },
  { value: 'first', label: 'I' },
  { value: 'second', label: 'II' },
  { value: 'third', label: 'III' },
  { value: 'fourth', label: 'IV' },
  { value: 'fifth', label: 'V' },
  { value: 'sixth', label: 'VI' },
  { value: 'seventh', label: 'VII' },
  { value: 'eight', label: 'VIII' },
  { value: 'ninth', label: 'IX' },
];

// export const GENDER_VALUES = [
//   { value: 'Female', label: 'Female' },
//   { value: 'Male', label: 'Male' },
//   { value: 'Nonbinary', label: 'Nonbinary' },
//   { value: 'NoAnswer', label: 'Prefer not to respond' },
// ];
// export const ADDRESS_VALUES = [
//   { value: 'United States', label: 'U.S' },
//   { value: 'Overseas Military', label: 'Overseas Military' },
//   { value: 'International', label: 'International' },
// ];

// TODO: deprecated, remove
export const CITY_MILITARY_VALUES = [
  { value: ' ', label: '' },
  { value: 'apo', label: 'APO' },
  { value: 'fpo', label: 'FPO' },
];
// TODO: deprecated, remove
export const STATEPROVINCE_VALUES = [
  { value: ' ', label: '' },
  { value: 'europe', label: 'ARMED FORCES EUROPE' },
  { value: 'pacific', label: 'ARMED FORCES PACIFIC' },
  { value: 'americas', label: 'ARMED FORCES AMERICAS' },
];
// TODO: deprecated, remove
export const STATEPROVINCE_CANADA_VALUES = [
  { label: '', value: ' ' },
  { label: 'Alberta', value: 'AB' },
  { label: 'British Columbia', value: 'BC' },
  { label: 'Manitoba', value: 'MB' },
  { label: 'New Brunswick', value: 'NB' },
  { label: 'Newfoundland', value: 'NF' },
  { label: 'Newfoundland and Labrador', value: 'NL' },
  { label: 'Northwest Territories', value: 'NT' },
  { label: 'Nova Scotia', value: 'NS' },
  { label: 'Nunavut', value: 'NU' },
  { label: 'Ontario', value: 'ON' },
  { label: 'Prince Edward Island', value: 'PE' },
  { label: 'Quebec', value: 'QC' },
  { label: 'Saskatchewan', value: 'SK' },
  { label: 'Yukon', value: 'YT' },
];
// TODO: deprecated, remove
export const STATEPROVINCE_MEXICO_VALUES = [
  { label: '', value: ' ' },
  { label: 'Aguascalientes', value: 'AGS' },
  { label: 'Baja California', value: 'BAJ' },
  { label: 'Baja California Sur', value: 'BCS' },
  { label: 'Campeche', value: 'CAMP' },
  { label: 'Chiapas', value: 'CHIS' },
  { label: 'Chihuahua', value: 'CHIH' },
  { label: 'Coahuila', value: 'COAH' },
  { label: 'Colima', value: 'COL' },
  { label: 'Distrito Federal', value: 'DF' },
  { label: 'Durango', value: 'DGO' },
  { label: 'Guanajuato', value: 'GUA' },
  { label: 'Guerrero', value: 'GRO' },
  { label: 'Hidalgo', value: 'HGO' },
  { label: 'Jalisco', value: 'JAL' },
  { label: 'Mexico', value: 'MEX' },
  { label: 'Michoacan', value: 'MICH' },
  { label: 'Morelos', value: 'MOR' },
  { label: 'Nayarit', value: 'NAY' },
  { label: 'Nuevo Leon', value: 'NL' },
  { label: 'Oaxaca', value: 'OAX' },
  { label: 'Puebla', value: 'PUE' },
  { label: 'Queretaro', value: 'QRO' },
  { label: 'Quintana Roo', value: 'QR' },
  { label: 'San Luis Potosi', value: 'SLP' },
  { label: 'Sinaloa', value: 'SIN' },
  { label: 'Sonora', value: 'SON' },
  { label: 'Tabasco', value: 'TAB' },
  { label: 'Tamaulipas', value: 'TAMP' },
  { label: 'Tlaxcala', value: 'TLAX' },
  { label: 'Veracruz', value: 'VER' },
  { label: 'Yucatan', value: 'YUC' },
  { label: 'Zacatecas', value: 'ZAC' },
];

export const VISATYPES = [
  { label: 'A-1', value: 'A-1', description: 'Diplomats and foreign government officials A-1' },
  { label: 'A-2', value: 'A-2', description: 'Foreign military personnel stationed in the U.S.' },
  { label: 'A-3', value: 'A-3', description: 'Diplomats and foreign government officials A-3' },
  { label: 'Asylee', value: 'Asylee', description: 'Asylee' },
  // { label: 'B-1', value: 'B-1', description: 'B-1' },
  { label: 'E-1', value: 'E-1', description: 'Treaty traders/treaty investors 1' },
  { label: 'E-2', value: 'E-2', description: 'Treaty traders/treaty investors 2' },
  { label: 'E-3', value: 'E-3', description: 'Australian professional specialty 3' },
  // { label: 'F-1', value: 'F-1', description: 'F-1' },
  // { label: 'F-2', value: 'F-2', description: 'F-2' },
  { label: 'G-1', value: 'G-1', description: 'Employees of a designated international organization, and NATO' },
  { label: 'G-2', value: 'G-2', description: 'Employees of a designated international organization, and NATO' },
  { label: 'G-3', value: 'G-3', description: 'Employees of a designated international organization, and NATO' },
  { label: 'G-4', value: 'G-4', description: 'Employees of a designated international organization, and NATO' },
  { label: 'G-5', value: 'G-5', description: 'Employees of a designated international organization, and NATO' },
  { label: 'H-1B', value: 'H-1B', description: 'Specialty occupations in fields requiring highly specialized knowledge' },
  { label: 'H-1B1', value: 'H-1B1', description: 'Free Trade Agreement (FTA) Professionals: Chile, Singapore' },
  { label: 'H-2A', value: 'H-2A', description: 'Temporary agricultural workers' },
  { label: 'H-2B', value: 'H-2B', description: 'Temporary workers performing other services or labor of a temporary or seasonal nature' },
  { label: 'H-3', value: 'H-3', description: 'Training in a program not primarily for employment' },
  { label: 'H-4', value: 'H-4', description: 'H-4' },
  { label: 'I', value: 'I', description: 'Media, journalists' },
  { label: 'J-1', value: 'J-1', description: 'Professor, scholar, teacher (exchange visitor)' },
  { label: 'J-2', value: 'J-2', description: 'Professor, scholar, teacher (exchange visitor)' },
  { label: 'K-3', value: 'K-3', description: 'K-3' },
  { label: 'K-4', value: 'K-4', description: 'K-4' },
  { label: 'L-1', value: 'L-1', description: 'Intra-company transferees' },
  { label: 'L-2', value: 'L-2', description: 'Intra-company transferees' },
  // { label: 'M-1', value: 'M-1', description: 'M-1' },
  // { label: 'M-2', value: 'M-2', description: 'M-2' },
  { label: 'N-8', value: 'N-8', description: 'N-8' },
  { label: 'N-9', value: 'N-9', description: 'N-9' },
  { label: 'NATO-1', value: 'NATO-1', description: 'Foreign military personnel stationed in the U.S' },
  { label: 'NATO-2', value: 'NATO-2', description: 'Foreign military personnel stationed in the U.S' },
  { label: 'NATO-3', value: 'NATO-3', description: 'Foreign military personnel stationed in the U.S' },
  { label: 'NATO-4', value: 'NATO-4', description: 'Foreign military personnel stationed in the U.S' },
  { label: 'NATO-5', value: 'NATO-5', description: 'Foreign military personnel stationed in the U.S' },
  { label: 'NATO-6', value: 'NATO-6', description: 'Foreign military personnel stationed in the U.S' },
  { label: 'NATO-7', value: 'NATO-7', description: 'Foreign military personnel stationed in the U.S' },
  { label: 'O-1', value: 'O-1', description: 'Foreign nationals with extraordinary ability in Sciences, Arts, Education, Business or Athletics' },
  { label: 'O-2', value: 'O-2', description: 'Foreign nationals with extraordinary ability in Sciences, Arts, Education, Business or Athletics' },
  { label: 'O-3', value: 'O-3', description: 'Foreign nationals with extraordinary ability in Sciences, Arts, Education, Business or Athletics' },
  { label: 'Other', value: 'Other', description: 'Other' },
  { label: 'P-1', value: 'P-1', description: 'Performing athletes, artists, entertainers' },
  { label: 'P-2', value: 'P-2', description: 'Performing athletes, artists, entertainers' },
  { label: 'P-3', value: 'P-3', description: 'Performing athletes, artists, entertainers' },
  { label: 'P-4', value: 'P-4', description: 'Performing athletes, artists, entertainers' },
  { label: 'Permanent Resident', value: 'Permanent Resident', description: 'Permanent Resident' },
  { label: 'Q-1', value: 'Q-1', description: 'International cultural exchange visitors' },
  { label: 'Q-2', value: 'Q-2', description: 'International cultural exchange visitors' },
  { label: 'Q-3', value: 'Q-3', description: 'International cultural exchange visitors' },
  { label: 'R-1', value: 'R-1', description: 'Religious worker' },
  { label: 'R-2', value: 'R-2', description: 'Religious worker' },
  { label: 'Refugee', value: 'Refugee', description: 'Refugee' },
  { label: 'S-5', value: 'S-5', description: 'S-5' },
  { label: 'S-6', value: 'S-6', description: 'S-6' },
  { label: 'S-7', value: 'S-7', description: 'S-7' },
  { label: 'T-1', value: 'T-1', description: 'Victims of Human Trafficking' },
  { label: 'T-2', value: 'T-2', description: 'Victims of Human Trafficking' },
  { label: 'T-3', value: 'T-3', description: 'Victims of Human Trafficking' },
  { label: 'T-4', value: 'T-4', description: 'Victims of Human Trafficking' },
  { label: 'T-5', value: 'T-5', description: 'Victims of Human Trafficking' },
  { label: 'TD', value: 'TD', description: 'NAFTA professional workers: Mexico, Canada' },
  { label: 'TN', value: 'TN', description: 'NAFTA professional workers: Mexico, Canada' },
  { label: 'Temporary Protected Status', value: 'Temporary Protected Status', description: 'Temporary Protected Status' },
  { label: 'U-1', value: 'U-1', description: 'Victims of Criminal Activity' },
  { label: 'U-2', value: 'U-2', description: 'Victims of Criminal Activity' },
  { label: 'U-3', value: 'U-3', description: 'Victims of Criminal Activity' },
  { label: 'U-4', value: 'U-4', description: 'Victims of Criminal Activity' },
  { label: 'U-5', value: 'U-5', description: 'Victims of Criminal Activity' },
  { label: 'V-1', value: 'V-1', description: 'V-1' },
  { label: 'V-2', value: 'V-2', description: 'V-2' },
  { label: 'V-3', value: 'V-3', description: 'V-3' },
];

export const LEARNING_FORMAT_VALUES = [
  { value: 'Online', label: 'Online' },
  { value: 'On Campus', label: 'On Campus' },
];

export const START_DATE_VALUES = [
  { value: 'Aug 30, 2019', label: '08-30-2019' },
  { value: 'Oct 15, 2019', label: '10-15-2019' },
  { value: 'Dec 20, 2019', label: '12-20-2019' },
];

export const LOCATION_VALUES = [
  { value: 'Bay Area Campus', label: 'Bay Area Campus' },
  { value: 'Central Valley Campus', label: 'Central Valley Campus' },
  { value: 'Livermore Learning Center', label: 'Livermore Learning Center' },
];
// PHONE NUMBER
export const UOPX_PHONE_NUMBER = '800-600-0899';
export const UOPX_HELP_DESK_PHONE_NUMBER = '800-613-1637';
export const UOPX_ENROLLMENT_REP = '844-937-8679';
export const UOPX_ER_PHONE_NUMBER = '844-503-1518';
export const UOPX_ESI_PHONE_NUMBER = '602-557-7444';
export const phoneNumber10Digit = phoneNumber => phoneNumber.match(/\d/g).join('');
