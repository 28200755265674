/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import styles from './styles';

const useStyles = styles;

function LinkLongArrow(props) {
  const {
    id,
    ariaLabel,
    disabled,
    handleClick,
    label,
    hrefLink,
    color,
    to,
    ...propsToPass
  } = props;
  const classes = useStyles();

  return (
    <>
      {to === '' ? (
        <Button
          {...propsToPass}
          id={id}
          aria-label={ariaLabel}
          disabled={disabled}
          className={color === 'primary'
            ? clsx(classes.link, 'linkArrow link-primary')
            : clsx(classes.link, 'linkArrow link-secondary')}
          onClick={handleClick}
          endIcon={(
            <span className="arrowWrapper">
              <span aria-hidden className={clsx(classes.rightArrow, 'rightArrow')} />
            </span>
          )}
          href={hrefLink || null}
          role="link"
        >
          {label || 'Add a link label, please'}
        </Button>
      ) : (
        <Link
          {...propsToPass}
          id={id}
          aria-label={ariaLabel}
          disabled={disabled}
          className={color === 'primary'
            ? clsx(classes.link, 'linkArrow link-primary routerLink')
            : clsx(classes.link, 'linkArrow link-secondary routerLink')}
          to={to}
          onClick={handleClick}
          role="link"
        >
          {label || 'Add a link label, please'}
          {' '}
          <span className="arrowWrapperLink">
            <span aria-hidden className={clsx(classes.rightArrow, 'rightArrow')} />
          </span>
        </Link>
      )}
    </>
  );
}

LinkLongArrow.defaultProps = {
  id: '',
  ariaLabel: '',
  disabled: false,
  handleClick: () => { },
  hrefLink: '',
  to: '',
  color: 'primary',
};

LinkLongArrow.propTypes = {
  id: PropTypes.string,
  ariaLabel: PropTypes.string,
  handleClick: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  hrefLink: PropTypes.string,
  to: PropTypes.string,
  color: PropTypes.string,
};

export default LinkLongArrow;
