import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

function TextAccent({ variant, text, color }) {
  const useStyles = makeStyles(theme => ({
    accent: {
      '&.h1, &.h2': {
        fontSize: theme.spacing(2),
        fontWeight: 300,
        lineHeight: '1.2',
        marginBottom: theme.spacing(1.8),
        [theme.breakpoints.up('sm')]: {
          fontSize: theme.spacing(2.4),
        },
      },
    },
    text: {
      display: 'block',
    },
    line: {
      display: 'inline-block',
      width: theme.spacing(5.6),
      height: theme.spacing(1),
      borderBottomWidth: theme.spacing(0.4),
      borderBottomStyle: 'solid',
      '&.primary': {
        borderBottomColor: theme.palette.primary.main,
      },
      '&.secondary': {
        borderBottomColor: theme.palette.secondary.main,
      },
      '&.tertiary': {
        borderBottomColor: theme.palette.tertiary.main,
      },
    },
  }));

  const classes = useStyles();

  return (
    <Typography variant={`${variant}`} className={clsx(classes.accent, `${variant}`)}>
      <span className={classes.text}>{text}</span>
      <span className={clsx(classes.line, `${color}`)} />
    </Typography>
  );
}

TextAccent.propTypes = {
  variant: PropTypes.string,
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
};

TextAccent.defaultProps = {
  variant: 'h1',
  color: 'primary',
};

export default TextAccent;
