import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { ReactComponent as LeftBackIcon } from '../../icons/left-long-arrow.svg';
import styles from './styles';

const useStyles = styles;

function BackButton(props) {
  const {
    id,
    ariaLabel,
    disabled,
    handleClick,
    label,
    hrefbackLink,
    ...propsToPass
  } = props;
  const classes = useStyles();

  return (
    <Button
      {...propsToPass}
      id={id}
      role="link"
      aria-label={ariaLabel}
      disabled={disabled}
      className="pageBackBtn"
      onClick={handleClick}
      startIcon={(
        <LeftBackIcon
          aria-hidden
          className={classes.backIcon}
        />
      )}
      href={hrefbackLink}
    >
      {label}
    </Button>
  );
}

BackButton.defaultProps = {
  id: '',
  ariaLabel: '',
  disabled: false,
  label: '',
  handleClick: () => { },
  hrefbackLink: '',
};

BackButton.propTypes = {
  id: PropTypes.string,
  ariaLabel: PropTypes.string,
  handleClick: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  hrefbackLink: PropTypes.string,
};

export default BackButton;
