import { selectFeatureFlag, UPDATE_FLAGS, FLOPFLIP_STATE_SLICE } from '@flopflip/react-redux';
import store from '../configureStore';
import featureFlags from '../featureFlags';
import defaultFeatureFlags from '../defaultFeatureFlags';

export const getFeatureFlag = name => (
  // Retrieve a feature flag by name
  // For example: featureFlags.ENABLE_TEALIUM
  // This is the preferred way to retrieve flags since
  // the flopflip redux slice is up to date with flags overriden by preferences
  selectFeatureFlag(name)(store.getState())
);

export const getSplitPercentage = variable => {
  // Retrieve the split percentage from a environment variable.
  // Will default to 0 if missing or invalid, this will in effect disable
  // the feature flag.
  // eslint-disable-next-line no-underscore-dangle
  const value = Number.parseFloat(window.__ENV[variable]);
  return Number.isNaN(value) ? 0 : value;
};

export const getSplitFlagValue = percent => {
  // Calculate the split and return true or false
  // Percent should be a number between 0 and 1
  if (typeof percent === 'number') {
    return Math.random() < percent;
  }
  throw new Error(`Invalid percentage: ${percent}`);
};

export const getFeatureFlags = () => {
  const flags = {};

  const sclpSplit = getSplitPercentage('REACT_APP_FUNDING_SCLP_SPLIT');
  // Avoiding setting the value to false if we are not actually running a split test yet
  if (sclpSplit !== 0) {
    if (getSplitFlagValue(sclpSplit)) {
      flags.SCLP_TEST_ENABLED = 'true';
    } else {
      flags.SCLP_TEST_ENABLED = 'false';
    }
  }
  const transitionPageSplit = getSplitPercentage('REACT_APP_TRANSITION_PAGE_SPLIT');
  // Avoiding setting the value to false if we are not actually running a split test yet
  if (transitionPageSplit !== 0) {
    if (getSplitFlagValue(transitionPageSplit)) {
      flags.TRANSITION_ENABLED = 'true';
    } else {
      flags.TRANSITION_ENABLED = 'false';
    }
  }
  const loanContributionSplit = getSplitPercentage('REACT_APP_LOAN_AND_CONTRIBUTION_MERGE_TEST_SPLIT');
  // Avoiding setting the value to false if we are not actually running a split test yet
  if (loanContributionSplit !== 0) {
    if (getSplitFlagValue(loanContributionSplit)) {
      flags.LOAN_AND_CONTRIBUTION_MERGE_TEST_ENABLED = 'true';
    } else {
      flags.LOAN_AND_CONTRIBUTION_MERGE_TEST_ENABLED = 'false';
    }
  }
  const acknowledgementsSplit = getSplitPercentage('REACT_APP_FUNDING_ACKNOWLEDGEMENTS_SPLIT');
  // Avoiding setting the value to false if we are not actually running a split test yet
  if (acknowledgementsSplit !== 0) {
    if (getSplitFlagValue(acknowledgementsSplit)) {
      flags.ACKNOWLEDGEMENTS_TEST_ENABLED = 'true';
    } else {
      flags.ACKNOWLEDGEMENTS_TEST_ENABLED = 'false';
    }
  }
  const displayERInfoSplit = getSplitPercentage('REACT_APP_TRANSITION_ER_INFO_SPLIT');
  // Avoiding setting the value to false if we are not actually running a split test yet
  if (displayERInfoSplit !== 0) {
    if (getSplitFlagValue(displayERInfoSplit)) {
      flags.DISPLAY_ER_INFO_ENABLED = 'true';
    } else {
      flags.DISPLAY_ER_INFO_ENABLED = 'false';
    }
  }
  const newNavigationSplit = getSplitPercentage('REACT_APP_NEW_NAVIGATION_SPLIT');
  // Avoiding setting the value to false if we are not actually running a split test yet
  if (newNavigationSplit !== 0) {
    if (defaultFeatureFlags[featureFlags.ENABLE_NAVIGATION_SWITCH]
      && getSplitFlagValue(newNavigationSplit)) {
      flags.ENABLE_NEW_NAVIGATION = 'true';
    } else {
      flags.ENABLE_NEW_NAVIGATION = 'false';
    }
  }
  const cyopRedesignSplit = getSplitPercentage('REACT_APP_CYOP_REDESIGN_SPLIT');
  // Avoiding setting the value to false if we are not actually running a split test yet
  if (cyopRedesignSplit !== 0) {
    if (getSplitFlagValue(cyopRedesignSplit)) {
      flags.CYOP_REDESIGN_ENABLED = 'true';
    } else {
      flags.CYOP_REDESIGN_ENABLED = 'false';
    }
  }
  const deviceAccessSplit = getSplitPercentage('REACT_APP_DEVICE_ACCESS_SPLIT');
  if (getSplitFlagValue(deviceAccessSplit)) {
    flags.ENABLE_DEVICE_ACCESS = 'true';
  } else {
    flags.ENABLE_DEVICE_ACCESS = 'false';
  }

  // get first page sessionStorage of new theme a/b test
  const newThemeTestValue = sessionStorage.getItem('newThemeABTest');
  // Check session storage for AB Test
  if (newThemeTestValue === 'true') {
    flags.NEW_THEME_TEST_ENABLED = 'true';
  }
  if (newThemeTestValue === 'false') {
    flags.NEW_THEME_TEST_ENABLED = 'false';
  }
  return flags;
};

export const applyFeatureFlagsToNav = (navigation, featureAllFlags) => {
  const interstitialPage = navigation.pages.find(page => page.label === 'Financial Plan')?.secondarySteps.find(secondaryStep => secondaryStep.label === 'Intro');
  if (interstitialPage) {
    if (featureAllFlags.ENABLE_NEW_NAVIGATION === 'true') {
      interstitialPage.enabled = true;
      interstitialPage.link = '/admissions/financial/choose-your-path';
      interstitialPage.label = 'Start Financial Plan';
    } else {
      interstitialPage.enabled = false;
    }
  }
  if (featureAllFlags.LOAN_AND_CONTRIBUTION_MERGE_TEST_ENABLED === 'true') {
    const personalContributionPage = navigation.pages.find(page => page.label === 'Financial Plan')?.secondarySteps.find(secondaryStep => secondaryStep.name === 'financialPersonal');
    const loanPage = navigation.pages.find(page => page.label === 'Financial Plan')?.secondarySteps.find(secondaryStep => secondaryStep.name === 'financialLoans');

    personalContributionPage.enabled = false;
    loanPage.enabled = true;
    loanPage.label = 'Contributions';
    loanPage.link = '/admissions/financial/contributions';
  }
  return navigation;
};

// Feature Flags
export const updateFlopFlipFeatureFlags = async (flagValues, dispatch) => {
  // Convert into right format and update in flopflip
  const flags = {};
  Object.keys(flagValues).forEach(flag => {
    const name = featureFlags[flag];
    if (name) {
      flags[name] = flagValues[flag] === 'true';
    }
  });
  await dispatch(
    {
      type: UPDATE_FLAGS,
      payload: {
        id: FLOPFLIP_STATE_SLICE,
        flags: {
          ...flags,
        },
      },
    },
  );
};
