import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box, Grid, Typography, CircularProgress,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  redirecting: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3.85),
  },
  loadingText: {
    display: 'inline',
    fontWeight: '500',
  },
  inlineSpinner: {
    alignItems: 'center',
    '& .loadingIcon .MuiCircularProgress-root': {
      verticalAlign: 'middle',
    },
  },
}));

function LoadingWithText({
  text,
  columnLayout,
}) {
  const classes = useStyles();

  return (
    <Box mb={4} role="alert" className={clsx(classes.redirecting, 'spinnerWrapper')}>
      {columnLayout ? (
        <Grid
          container
          spacing={1}
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item>
            <CircularProgress size={60} />
          </Grid>
          <Grid item>
            <Typography className={classes.loadingText}>
              {text}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={1} wrap="nowrap" className={classes.inlineSpinner}>
          <Grid item className="loadingIcon">
            <CircularProgress size={20} />
          </Grid>
          <Grid item className="loadingTypography">
            <Typography className={classes.loadingText}>
              {text}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

LoadingWithText.propTypes = {
  text: PropTypes.string,
  columnLayout: PropTypes.bool,
};

LoadingWithText.defaultProps = {
  text: 'Loading...',
  columnLayout: false,
};

export default LoadingWithText;
