/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
} from '@material-ui/core';
import {
  ExpandMore as ExpandMoreIcon,
  CheckCircle as CheckCircleIcon,
  OpenInNew as OpenInNewWindow,
} from '@material-ui/icons';
import { ReactComponent as TaskNotReceivedIcon } from '../../modules/admissions/components/icons/task_notreceived.svg';
import { ReactComponent as TaskReceivedIcon } from '../../modules/admissions/components/icons/task_received.svg';
import styles from './styles';
import LoadingWithText from '../loading/LoadingWithText';
import { triggerAlloyTrack } from '../../modules/vendor/alloy/AlloyUpdater';
import { getIncompleteTaskListStr, getTaskStatusStr } from '../../store/helpers/commonHelper';
import { sendCustomFullStoryEvent } from '../../modules/vendor/fullstory/FullStoryUpdater';

const useStyles = styles;

const TaskCollapsiblePanel = ({
  id,
  taskTag,
  title,
  taskType,
  personId,
  className,
  header,
  defaultExpanded,
  heading,
  link,
  iconLink,
  linkCaption,
  linkTarget,
  completed,
  userActionable,
  completePersonTask,
  description,
  disableLink,
  showLinkasButton,
  startIcon,
  endIcon,
  newWindow,
  needRedirect,
  incompleteAdmissionTasks,
  incompleteFinancialTasks,
  incompleteOptionalTasks,
  alertMessage,
  taskTags,
  statusMessage,
  statusLabel,
}) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(completed);
  const [startCheck, setStartCheck] = useState(false);
  const createMarkup = content => ({ __html: content });
  const redirectUrl = window.location.href;
  const [updatedLink, setUpdatedLink] = useState(link);

  const labellingTaskType = taskGroup => {
    if (taskGroup === 'admissionTasks') {
      return 'Admission';
    }
    if (taskGroup === 'financialTasks') {
      return 'Financial';
    }
    if (taskGroup === 'optionalTasks') {
      return 'Optional';
    }
    return taskGroup;
  };

  const sendToFS = () => {
    sendCustomFullStoryEvent('NAE_ENROLL_TASK_COMPLETE', {
      personId,
      taskType,
      title,
      userActionable,
      userTaskIncomplete: getIncompleteTaskListStr(
        incompleteAdmissionTasks,
        incompleteFinancialTasks,
        incompleteOptionalTasks,
      ),
      taskStatus: getTaskStatusStr(
        incompleteAdmissionTasks,
        incompleteFinancialTasks,
        incompleteOptionalTasks,
      ),
    });
  };

  useEffect(() => {
    if (link) {
      let newLink;
      if (link.includes('backUrl')) {
        newLink = newLink || link;
        const backUrlIndex = newLink.indexOf('backUrl');
        if (redirectUrl.includes('?')) {
          // PREX-62548 Temp fix to push skipStartNewAppModal as the first query param so that
          // redirection from demographics page retains it.
          const baseUrl = `${redirectUrl.substring(0, redirectUrl.indexOf('?'))}`;
          const queryParam = `${redirectUrl.substring(redirectUrl.indexOf('?') + 1)}`;
          newLink = `${newLink.substring(0, backUrlIndex - 1)}&backUrl=${baseUrl}?skipStartNewAppModal=true&${queryParam}`;
        } else {
          newLink = `${newLink.substring(0, backUrlIndex - 1)}&backUrl=${redirectUrl}?skipStartNewAppModal=true`;
        }
      }
      if (needRedirect) {
        newLink = newLink || link;
        if (newLink.includes('?')) {
          newLink = `${newLink}&redirectUrl=${redirectUrl}`;
        } else {
          newLink = `${newLink}?redirectUrl=${redirectUrl}`;
        }
        if (redirectUrl.includes('?')) {
          newLink = `${newLink}&skipStartNewAppModal=true`;
        } else {
          newLink = `${newLink}?skipStartNewAppModal=true`;
        }
      }
      if (newLink) {
        setUpdatedLink(newLink);
      }
    }
  }, [needRedirect, link]);

  useEffect(() => {
    if (completed) {
      setChecked(true);
      setStartCheck(false);
      if (userActionable && (sessionStorage.getItem(`task_${id}`) === 'completingTask')) {
        const taskNaming = labellingTaskType(taskType);
        triggerAlloyTrack('EnrollmentTaskCompleted', {
          componentName: 'Application Task Status',
          name: `Enrollment - ${taskNaming} Task Completed`,
          enrollmentTaskDetails: {
            taskName: title,
            taskGroup: taskNaming,
            userTaskIncomplete: getIncompleteTaskListStr(
              incompleteAdmissionTasks,
              incompleteFinancialTasks,
              incompleteOptionalTasks,
            ),
            taskStatus: getTaskStatusStr(
              incompleteAdmissionTasks,
              incompleteFinancialTasks,
              incompleteOptionalTasks,
            ),
          },
        }, { personId });
        sessionStorage.removeItem(`task_${id}`);
        sendToFS();
      }
    }
  }, [completed]);

  useEffect(() => {
    if (!userActionable) {
      if (!completed) {
        sessionStorage.setItem(`task_${taskTag}`, 'false');
      }
      let incompleteTaskListStr = '';
      if (taskType === 'admissionTasks' && completed && sessionStorage.getItem(`task_${taskTag}`) === 'false') {
        const index = incompleteAdmissionTasks.indexOf(`admission - ${title}`);
        if (index > -1) {
          incompleteAdmissionTasks.splice(index, 1);
        }
        incompleteTaskListStr = getIncompleteTaskListStr(
          incompleteAdmissionTasks,
          incompleteFinancialTasks,
          incompleteOptionalTasks,
        );

        triggerAlloyTrack('EnrollmentTaskCompleted', {
          componentName: 'Application Task Status',
          name: 'Enrollment - Admission Task Completed',
          enrollmentTaskDetails: {
            taskName: title,
            taskGroup: 'Admission',
            userTaskIncomplete: incompleteTaskListStr,
            taskStatus: getTaskStatusStr(
              incompleteAdmissionTasks,
              incompleteFinancialTasks,
              incompleteOptionalTasks,
            ),
          },
        }, { personId });
        sessionStorage.removeItem(`task_${taskTag}`);
        sendToFS();
      }
      if (taskType === 'financialTasks' && completed && sessionStorage.getItem(`task_${taskTag}`) === 'false') {
        const index = incompleteFinancialTasks.indexOf(`financial - ${title}`);
        if (index > -1) {
          incompleteFinancialTasks.splice(index, 1);
        }
        incompleteTaskListStr = getIncompleteTaskListStr(
          incompleteAdmissionTasks,
          incompleteFinancialTasks,
          incompleteOptionalTasks,
        );

        triggerAlloyTrack('EnrollmentTaskCompleted', {
          componentName: 'Application Task Status',
          name: 'Enrollment - Financial Task Completed',
          enrollmentTaskDetails: {
            taskName: title,
            taskGroup: 'Financial',
            userTaskIncomplete: incompleteTaskListStr,
            taskStatus: getTaskStatusStr(
              incompleteAdmissionTasks,
              incompleteFinancialTasks,
              incompleteOptionalTasks,
            ),
          },
        }, { personId });
        sessionStorage.removeItem(`task_${taskTag}`);
        sendToFS();
      }
      if (taskType === 'optionalTasks' && completed && sessionStorage.getItem(`task_${taskTag}`) === 'false') {
        const index = incompleteOptionalTasks.indexOf(`optional - ${title}`);
        if (index > -1) {
          incompleteOptionalTasks.splice(index, 1);
        }
        incompleteTaskListStr = getIncompleteTaskListStr(
          incompleteAdmissionTasks,
          incompleteFinancialTasks,
          incompleteOptionalTasks,
        );

        triggerAlloyTrack('EnrollmentTaskCompleted', {
          componentName: 'Application Task Status',
          name: 'Enrollment - Optional Task Completed',
          enrollmentTaskDetails: {
            taskName: title,
            taskGroup: 'Optional',
            userTaskIncomplete: incompleteTaskListStr,
            taskStatus: getTaskStatusStr(
              incompleteAdmissionTasks,
              incompleteFinancialTasks,
              incompleteOptionalTasks,
            ),
          },
        }, { personId });
        sessionStorage.removeItem(`task_${taskTag}`);
        sendToFS();
      }
    }
  }, [userActionable, taskType, completed]);

  const handleChange = () => e => {
    setStartCheck(true);
    sessionStorage.setItem(`task_${id}`, 'completingTask');
    completePersonTask(personId, id);
  };

  const handleExpandTask = complete => e => {
    if (!complete) {
      const accordionState = e.currentTarget;
      const expand = accordionState.querySelector('.Mui-expanded');
      if (expand === null) {
        const taskNaming = labellingTaskType(taskType);
        triggerAlloyTrack('EnrollmentTaskExpanded', {
          componentName: 'Application Task Status',
          name: `Enrollment - ${taskNaming} Task Expanded`,
          enrollmentTaskDetails: {
            taskName: title,
            taskGroup: taskNaming,
            userTaskIncomplete: getIncompleteTaskListStr(
              incompleteAdmissionTasks,
              incompleteFinancialTasks,
              incompleteOptionalTasks,
            ),
            taskStatus: getTaskStatusStr(
              incompleteAdmissionTasks,
              incompleteFinancialTasks,
              incompleteOptionalTasks,
            ),
          },
        }, { personId });
      }
    }
  };

  const handleMarkAction = (isChecked, taskHeader) => {
    if (startCheck) {
      return (
        <div
          className="checkCheckboxSpinner"
          role="alert"
          aria-label={`Mark as completed is now being saved for ${taskHeader} task`}
        >
          <LoadingWithText text="Mark as completed" />
        </div>
      );
    }
    return (
      <Checkbox
        checked={isChecked}
        onChange={handleChange()}
        inputProps={{ 'aria-label': 'controlled' }}
        disabled={isChecked}
      />
    );
  };
  const CTAavailableAfterTaskCompletion = ['MakePaymentTask', 'CreditCardOnFileTask', 'PortfolioFeePaymentTask', 'CreditBalanceTask', 'MpnTask', 'PlusMpnTask',
    'ParentPlusTask', 'ECTask', 'FafsaTask', 'MilitaryFlatRateSpouseTask', 'CertificateClearanceTask', 'TuitionBenefitsTask', 'ScholarshipTask'];
  const enabledLinkTags = CTAavailableAfterTaskCompletion.includes(taskTags);
  return (
    <Accordion
      id={taskTag}
      defaultExpanded={defaultExpanded}
      className={clsx(
        classes.panelRoot,
        `taskAccordionPanel ${className !== '' ? className : ''}`,
      )}
      data-task-completed={checked}
    >
      <AccordionSummary
        aria-controls={`${taskTag}-content`}
        // focusVisibleClassName
        expandIcon={<ExpandMoreIcon />}
        onClick={handleExpandTask(checked)}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          wrap="nowrap"
        >
          <Grid item className={clsx(classes.iconItem, 'iconStatus')}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item className={classes.iconAlign}>
                <CheckCircleIcon
                  role="img"
                  aria-hidden="false"
                  focusable=""
                  aria-label={`${
                    checked ? 'Task completed' : 'Task incomplete'
                  }`}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={clsx(classes.headerItem, 'headerText')}>
            <Typography id={`${taskTag}-header`} variant={heading}>
              {header}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          {alertMessage && (
            <p className="block-msg info-msg">{alertMessage}</p>
          )}
          <span
            className={classes.taskContent}
            dangerouslySetInnerHTML={createMarkup(description)}
          />
          {statusMessage && (
            <div className={classes.statusBlock}>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="center"
                className={classes.mainGrid}
              >
                <Grid item xs={12}>
                  <Paper variant="outlined" className={classes.paper}>
                    <div className={classes.statusMessage}>
                      <div
                        className={clsx(classes.taskContent, classes.descMsg)}
                        dangerouslySetInnerHTML={createMarkup(statusMessage)}
                      />
                      <div className={classes.statusContent}>
                        <Typography variant="text" className={classes.text}>
                          Status:
                        </Typography>
                        <sapn className={classes.notificationStatusIcon}>
                          {statusLabel === 'Received' && <TaskReceivedIcon id={`${taskTag}-icon`} aria-hidden />}
                          {statusLabel === 'Not received' && <TaskNotReceivedIcon id={`${taskTag}-icon`} aria-hidden />}
                        </sapn>
                        <span className={classes.statusLabel}>{statusLabel}</span>
                      </div>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </div>
          )}
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            className={`taskActions ${linkCaption === null ? 'noCTA' : ''}`}
          >
            <Grid item>
              {link && !iconLink ? (
                <Button
                  href={updatedLink}
                  target={`${newWindow ? '_blank' : ''}`}
                  role="link"
                  aria-label={`${
                    linkTarget === '_blank'
                      ? `${linkCaption}, opens in new window`
                      : linkCaption
                  }`}
                  variant={`${showLinkasButton || !checked ? 'contained' : ''}`}
                  className={`${
                    showLinkasButton || !checked ? 'mainBtn' : 'smallCtaBtn'
                  }`}
                  color="secondary"
                  disableFocusRipple
                  disableRipple
                  disabled={checked && disableLink && !enabledLinkTags}
                  startIcon={startIcon}
                  endIcon={linkTarget === '_blank' ? endIcon : ''}
                >
                  {linkCaption}
                </Button>
              ) : null}
              {link && iconLink ? (
                <Grid
                  container
                  wrap="nowrap"
                  className="linkWithIcon"
                  alignItems="center"
                >
                  <Grid item>
                    <Button
                      href={updatedLink}
                      target={`${newWindow ? '_blank' : ''}`}
                      className="smallCtaBtn ctaLink"
                      disableFocusRipple
                      disableRipple
                      disabled={checked && disableLink}
                      role="link"
                      aria-label={`${
                        linkTarget === '_blank'
                          ? `${linkCaption}, opens in new window`
                          : linkCaption
                      }`}
                    >
                      {linkCaption}
                    </Button>
                  </Grid>
                  <Grid item>
                    {linkTarget === '_blank' ? <OpenInNewWindow /> : null}
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
            <Grid item>
              {userActionable && (
                <fieldset style={{ border: 'none' }} className="check-the-box">
                  <legend className="sr-only">
                    Mark as complete when the task is done
                  </legend>
                  <FormControlLabel
                    value="checked"
                    control={handleMarkAction(checked, header)}
                    label="Mark as completed"
                  />
                </fieldset>
              )}
            </Grid>
          </Grid>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

TaskCollapsiblePanel.propTypes = {
  taskTag: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  taskType: PropTypes.string.isRequired,
  personId: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  className: PropTypes.string,
  header: PropTypes.string.isRequired,
  defaultExpanded: PropTypes.bool,
  heading: PropTypes.string,
  link: PropTypes.string,
  iconLink: PropTypes.bool,
  linkCaption: PropTypes.string,
  linkTarget: PropTypes.string,
  completed: PropTypes.bool,
  userActionable: PropTypes.bool,
  completePersonTask: PropTypes.func.isRequired,
  disableLink: PropTypes.bool,
  showLinkasButton: PropTypes.bool,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  newWindow: PropTypes.bool,
  needRedirect: PropTypes.bool,
  incompleteAdmissionTasks: PropTypes.shape([]),
  incompleteFinancialTasks: PropTypes.shape([]),
  incompleteOptionalTasks: PropTypes.shape([]),
  alertMessage: PropTypes.string,
  taskTags: PropTypes.string,
  statusMessage: PropTypes.string,
  statusLabel: PropTypes.string,
};

TaskCollapsiblePanel.defaultProps = {
  className: '',
  defaultExpanded: false,
  heading: 'h3',
  link: '',
  iconLink: false,
  linkCaption: '',
  linkTarget: '',
  completed: false,
  userActionable: false,
  disableLink: true,
  showLinkasButton: true,
  startIcon: null,
  endIcon: null,
  newWindow: true,
  needRedirect: false,
  incompleteAdmissionTasks: [],
  incompleteFinancialTasks: [],
  incompleteOptionalTasks: [],
  alertMessage: '',
  taskTags: '',
  statusMessage: '',
  statusLabel: '',
};

export default TaskCollapsiblePanel;
