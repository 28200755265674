/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function SkipTo() {
  const location = useLocation();
  const [hasChat, setHasChat] = useState(false);

  const focusEl = tag => {
    tag.setAttribute('tabindex', '0');
    tag.focus();
  };
  const jumpToHeader = event => {
    event.preventDefault();
    const mainHeading = document.querySelector('h1');
    if (mainHeading) {
      focusEl(mainHeading);
    } else {
      const mainTag = document.querySelector('main');
      focusEl(mainTag);
    }
  };
  const jumpToChat = event => {
    event.preventDefault();
    const chatBtn = document.querySelector('#supportMenuButton');
    if (chatBtn) {
      chatBtn.focus();
    }
  };
  const isJumpToChatNeeded = () => {
    if (location.pathname.indexOf('/logout') !== -1) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    setHasChat(isJumpToChatNeeded);
  }, [location]);

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <nav aria-label="jump to content" data-utid="skipto" id="skipto">
      <ul className="skip-list">
        <li className="to-main">
          <a className="skip-to-content" href="#" onClick={jumpToHeader}>Skip to main content</a>
        </li>
        {hasChat && (
          <li className="to-chat">
            <a className="skip-to-content" href="#" onClick={jumpToChat}>Skip to chat</a>
          </li>
        )}
      </ul>
    </nav>
  );
}

export default SkipTo;
