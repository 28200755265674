import { errorToMessage } from '../../../../errorMessaging';
import STATUS from '../../../globalStatuses';
import { ACTIONS } from './types';

function studentNotification(
  state = {
    studentNotification: {
    },
    studentNotificationStatus: {
      getStatus: STATUS.UNFETCHED,
      modifyStatus: STATUS.UNFETCHED,
      errorData: null,
    }
  },
  action,
) {
  switch (action.type) {
    case ACTIONS.REQUEST_GET_STUDENT_NOTIFICATION:
      return {
        ...state,
        studentNotificationStatus: {
          ...state.studentNotificationStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_GET_STUDENT_NOTIFICATION:
      return {
        ...state,
        studentNotification: action.notifications,
        studentNotificationStatus: {
          ...state.studentNotificationStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case ACTIONS.REQUEST_GET_STUDENT_NOTIFICATION_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        studentNotificationStatus: {
          ...state.studentNotificationStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    default:
      return state;
  }
}

export default studentNotification;
