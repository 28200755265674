import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import clsx from 'clsx';
import styles from './styles';

const useStyles = styles;
function ConfirmDialog(props) {
  const classes = useStyles();
  const {
    open, setOpen, title, body, body2, onNoClick, onYesClick,
    dialogId, listItems, bodyEnd, actions, cancelAsClose,
  } = props;

  const handleAction1 = () => {
    if (cancelAsClose) {
      setOpen(false);
    } else {
      onNoClick();
    }
  };

  return (
    <Dialog
      className="Dialog"
      open={open}
      aria-labelledby="confirm-dialog-title"
      id={dialogId}
    >
      <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
      {body ? (
        <DialogContent className="tight">
          <DialogContentText className={classes.text}>
            {body}
            {(listItems && listItems.length > 1) ? (
              <ul className={clsx(classes.list, 'unordered-list main')}>
                {listItems.map((item, i) => <li key={i.toString()}><span>{item}</span></li>)}
              </ul>
            ) : null}
            {body2 ? (
              <>
                <br />
                <br />
                {body2}
              </>
            ) : null}
            {bodyEnd || null}
          </DialogContentText>
        </DialogContent>
      ) : null}
      <DialogActions className={classes.dialogActions}>
        {(actions && actions.length === 2) ? (
          <>
            <Button
              // onClick={() => setOpen(false)}
              onClick={handleAction1}
              id="confirm-dialog-title-yes"
              color="secondary"
              className="mainBtn confirmBtn"
              disableFocusRipple
              disableRipple
            >
              {actions[0]}
            </Button>
            <Button
              onClick={onYesClick}
              id="confirm-dialog-title-no"
              color="primary"
              className="mainBtn confirmBtn"
              autoFocus
              disableFocusRipple
              disableRipple
            >
              {actions[1]}
            </Button>
          </>
        ) : (
          <>
            <Button
              onClick={onYesClick}
              id="confirm-dialog-title-yes"
              color="secondary"
              className="mainBtn confirmBtn"
              disableFocusRipple
              disableRipple
            >
              Yes
            </Button>
            <Button
              // onClick={() => setOpen(false)}
              onClick={handleAction1}
              id="confirm-dialog-title-no"
              color="primary"
              className="mainBtn confirmBtn"
              autoFocus
              disableFocusRipple
              disableRipple
            >
              No
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}

ConfirmDialog.defaultProps = {
  body: '',
  body2: '',
  title: '',
  listItems: [],
  bodyEnd: '',
  actions: [],
  dialogId: '',
  cancelAsClose: true,
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  body: PropTypes.string,
  body2: PropTypes.string,
  setOpen: PropTypes.func.isRequired,
  onNoClick: PropTypes.func.isRequired,
  onYesClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  listItems: PropTypes.arrayOf(PropTypes.any),
  bodyEnd: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.any),
  dialogId: PropTypes.string,
  cancelAsClose: PropTypes.bool,
};

export default ConfirmDialog;
