import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    margin: 0,
    padding: 0,
    '& .ctaHeaderGrid': {
      '&.documentsHeaderGrid': {
        marginBottom: '0.8rem',
      },
      '&.laptopHeaderGrid': {
        marginBottom: '1.6rem',
      },
    },
    '& .iconIdent': {
      marginLeft: theme.spacing(5.8),
    },
    '& .errorIconGrid': {
      width: '32px!important',
      height: 'auto',
      margin: '0 8px',
    },
  },
  outlineCardContent: {
    borderRadius: theme.spacing(0.4),
    borderColor: theme.palette.common.darkGrey,
    borderWidth: theme.spacing(0.1),
    borderStyle: 'solid',
    padding: theme.spacing(1.2),
  },
  centerCardContent: {
    textAlign: 'center',
    '& .ctaHeaderGrid': {
      width: '100%!important',
      display: 'inline-block!important',
      '& > div': {
        display: 'inline-block',
      },
      '& > div:last-child': {
        verticalAlign: 'top',
      },
    },
    '& .documentsHeaderGrid': {
      '& div.MuiGrid-item:last-child': {
        paddingTop: theme.spacing(1.6),
      },
    },
  },
  header: {
    fontSize: '1.6rem!important',
    fontWeight: '700!important',
    [theme.breakpoints.up('sm')]: {
      fontSize: '2rem!important',
    },
  },
  paragraph: {
    paddingBottom: theme.spacing(1.2),
  },
  cta: {
    '& button': {
      marginTop: theme.spacing(0.8),
    },
    '& .linkArrow.routerLink': {
      fontSize: theme.spacing(1.6),
      textDecoration: 'none',
      padding: '0.6rem 8rem 0.6rem 0',
      lineHeight: 2.5,
    },
  },
  ctaLinkType: {
    '& button.MuiButton-text': {
      marginTop: '0!important',
    },
  },
  disabledCard: {
    '& svg': {
      opacity: '0.5',
    },
    '& .ctaHeaderGrid': {
      color: '#5e7079!important',
    },
    '& .ctaParagraph': {
      color: '#5e7079!important',
    },
    '& .unordered-list': {
      color: '#5e7079!important',
    },
    '& .linkArrow': {
      color: '#5e7079!important',
    },
    '& .rightArrow::before, & .rightArrow::after': {
      borderColor: '#5e7079!important',
    },
  },
  circularProgress: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.adaGrey,
  },
}));
