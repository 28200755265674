/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import {
  Typography, Button,
} from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  ReactComponent as StudentLife,
} from '../../modules/admissions/components/illustration/StudentLife.svg';
import {
  ReactComponent as StudentPortal,
} from '../../modules/admissions/components/illustration/GoToSchool.svg';
import {
  ReactComponent as StudentLifeDisabled,
} from '../../modules/admissions/components/illustration/StudentLifeGrayscale.svg';
import {
  ReactComponent as StudentPortalDisabled,
} from '../../modules/admissions/components/illustration/GoToSchoolGrayscale.svg';
import styles from './styles';

export default function CtaWithImageCard({
  id,
  mainHeading,
  heading,
  image,
  mainHeader,
  header,
  paragraph,
  ctaLabel,
  ctaRoleType,
  ariaLabel,
  buttonColor,
  buttonType,
  buttonIcon,
  buttonTargetBlank,
  hrefLink,
  handleClick,
  disabledCard,
}) {
  const classes = styles();
  const [imageDisplay, setImageDisplay] = useState(null);

  useEffect(() => {
    if (image === 'studentLife') {
      if (disabledCard) {
        setImageDisplay(
          <div className={classes.studentLife}>
            <StudentLifeDisabled className={classes.imageCTA} />
          </div>,
        );
      } else {
        setImageDisplay(
          <div className={classes.studentLife}>
            <StudentLife className={classes.imageCTA} />
          </div>,
        );
      }
    } else if (image === 'studentPortal') {
      if (disabledCard) {
        setImageDisplay(
          <div className={classes.studentPortal}>
            <StudentPortalDisabled className={classes.imageCTA} />
          </div>,
        );
      } else {
        setImageDisplay(
          <div className={classes.studentPortal}>
            <StudentPortal className={classes.imageCTA} />
          </div>,
        );
      }
    }
  }, [disabledCard, image]);

  return (
    <div
      className={
        clsx(
          classes.root,
          `${disabledCard ? 'disabled' : ''}`,
          'ctaWithImageCard',
        )
      }
      id={id}
    >
      <div className="ctaWithImageCardArea1">
        {mainHeader !== '' ? (
          <Typography
            variant={mainHeading}
            className={clsx(classes.mainHeader, 'eyebrowText')}
            data-cy="main-header-text"
          >
            {mainHeader}
          </Typography>
        ) : null}
      </div>
      <div className="ctaWithImageCardArea2">
        {imageDisplay !== null ? (imageDisplay) : null}
      </div>
      <div className="ctaWithImageCardArea3">
        {header !== '' ? (
          <Typography variant={heading} className={clsx(classes.header, 'secondaryHeader')}>
            {header}
          </Typography>
        ) : null}
        {paragraph ? (
          <Typography className={clsx(classes.paragraph, 'ctaParagraph')}>
            {paragraph}
          </Typography>
        ) : null}
        {ctaLabel ? (
          <div className={classes.cta}>
            <Button
              className={`mainBtn ${buttonColor === 'blue' ? 'colorOverBtn' : ''}`}
              color={buttonType}
              ariaLabel={ariaLabel}
              disableRipple
              disableFocusRipple
              onClick={() => { handleClick(); }}
              disabled={disabledCard}
              href={hrefLink !== null ? hrefLink : null}
              endIcon={buttonIcon === 'OpenInNewIcon' ? <OpenInNewIcon /> : null}
              target={buttonTargetBlank ? '_blank' : ''}
              role={ctaRoleType}
            >
              {ctaLabel}
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
}

CtaWithImageCard.defaultProps = {
  id: '',
  mainHeading: 'h3',
  heading: 'h4',
  image: 'studentPortal',
  mainHeader: '',
  header: '',
  paragraph: null,
  ctaLabel: null,
  ctaRoleType: 'button',
  ariaLabel: '',
  buttonColor: 'red',
  buttonType: 'primary',
  buttonIcon: '',
  buttonTargetBlank: false,
  hrefLink: null,
  handleClick: () => {},
  disabledCard: false,
};

CtaWithImageCard.propTypes = {
  id: PropTypes.string,
  mainHeading: PropTypes.string,
  heading: PropTypes.string,
  image: PropTypes.oneOf(['studentLife', 'studentPortal']),
  mainHeader: PropTypes.string,
  header: PropTypes.string,
  paragraph: PropTypes.string,
  ctaLabel: PropTypes.string,
  ctaRoleType: PropTypes.oneOf(['link', 'button']),
  ariaLabel: PropTypes.string,
  buttonColor: PropTypes.oneOf(['red', 'blue']),
  buttonType: PropTypes.oneOf(['primary', 'secondary']),
  buttonIcon: PropTypes.string,
  buttonTargetBlank: PropTypes.bool,
  hrefLink: PropTypes.string,
  handleClick: PropTypes.func,
  disabledCard: PropTypes.bool,
};
