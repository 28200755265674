import { proxyClientInstance } from './wrappers/axios';

import TaskService from './sagas/v1/task';
import ApplicationService from './application/v1/application';
import enumService from './enum/v1/enum';
import contributionService from './financialaid/v1/contribution';
import DocumentService from './document/v1/document';
import personService from './person/v1/person';
import workService from './person/v1/work';
import educationService from './person/v1/education';
import examService from './person/v1/exam';
import militaryService from './person/v1/military';
import citizenshipService from './person/v1/citizenship';
import CertificateService from './person/v1/certificate';
import associationsService from './person/v1/associations';
import walletService from './wallet/v1/wallet';
import LogOutService from './logout/v1/logout';
import IamService from './iam/v1/iam';
import ContactService from './contact/v1/contact';
import OrganizationService from './organization/v1/organization';
import PreferencesService from './preferences/v1/preferences';

import ProgramServiceV3 from './program/v3/program';

import CalculatorInputService from './financialaid/v1/calculatorinput';
import CalculatorSummaryService from './financialaid/v1/calculatorsummary';
import MilitaryBenefitsService from './financialaid/v1/militarybenefits';

import StudentService from './student/v1/student';
import ForgerockService from './forgerock/forgerock';
import AdminService from './admin/admin';
import DgspService from './dgsp/v1/dgsp';
import SalesforceService from './transition/v1/salesforce';
import StudentNotificationService from './financialaid/v1/studentNotification';

const getEnabledProgramService = proxyClient => ProgramServiceV3(proxyClient);

export default {
  taskService: TaskService(proxyClientInstance),
  applicationService: ApplicationService(proxyClientInstance),
  enumService: enumService(proxyClientInstance),
  contributionService: contributionService(proxyClientInstance),
  personService: personService(proxyClientInstance),
  workService: workService(proxyClientInstance),
  educationService: educationService(proxyClientInstance),
  examService: examService(proxyClientInstance),
  militaryService: militaryService(proxyClientInstance),
  citizenshipService: citizenshipService(proxyClientInstance),
  certificateService: CertificateService(proxyClientInstance),
  associationsService: associationsService(proxyClientInstance),
  walletService: walletService(proxyClientInstance),
  logoutService: LogOutService(proxyClientInstance),
  documentService: DocumentService(proxyClientInstance),
  iamService: IamService(proxyClientInstance),
  programService: getEnabledProgramService(proxyClientInstance),
  contactService: ContactService(proxyClientInstance),
  organizationService: OrganizationService(proxyClientInstance),
  calculatorInputService: CalculatorInputService(proxyClientInstance),
  calculatorSummaryService: CalculatorSummaryService(proxyClientInstance),
  militaryBenefitsService: MilitaryBenefitsService(proxyClientInstance),
  preferencesService: PreferencesService(proxyClientInstance),
  studentService: StudentService(proxyClientInstance),
  forgerockService: ForgerockService(),
  adminService: AdminService(),
  dgspService: DgspService(proxyClientInstance),
  salesforceService: SalesforceService(proxyClientInstance),
  studentNotificationService: StudentNotificationService(proxyClientInstance),
};
