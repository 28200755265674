/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import {
  Typography, Grid, Button, CircularProgress
} from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  ReactComponent as Laptop,
} from '../../modules/admissions/components/icons/UopxLaptopMedium.svg';
import {
  ReactComponent as Documents,
} from '../../modules/admissions/components/icons/DocumentsMedium.svg';
import {
  ReactComponent as LaptopList,
} from '../../modules/admissions/components/icons/ListMedium.svg';
import {
  ReactComponent as CertificateAward,
} from '../../modules/admissions/components/icons/certificate-award.svg';
import {
  ReactComponent as ErrorIcon,
} from '../../modules/admissions/components/icons/error.svg';
import LinkLongArrow from '../../modules/admissions/components/button/link-long-arrow/LinkLongArrow';
import styles from './styles';

export default function CtaWithIconCard({
  id,
  heading,
  icon,
  iconIdent,
  header,
  listItems,
  paragraph,
  ctaType,
  ctaRoleType,
  ctaLabel,
  ariaLabel,
  linkColor,
  buttonColor,
  buttonType,
  hrefLink,
  to,
  handleClick,
  outlineCardContent,
  centerCardContent,
  disabledCard,
  children,
  disabled,
}) {
  const classes = styles();
  const [iconDisplay, setIconDisplay] = useState(null);

  useEffect(() => {
    if (icon === 'documents') {
      setIconDisplay(
        <Laptop className="documentsIconGrid" />,
      );
    } else if (icon === 'laptop') {
      setIconDisplay(
        <LaptopList className="laptopIconGrid" />,
      );
    } else if (icon === 'list') {
      setIconDisplay(
        <Documents className="documentsIconGrid" />,
      );
    } else if (icon === 'certificateAward') {
      setIconDisplay(
        <CertificateAward className="certAwardIconGrid" />,
      );
    } else if (icon === 'error') {
      setIconDisplay(
        <ErrorIcon className="errorIconGrid" />,
      );
    }
  }, [disabledCard, icon]);

  return (
    <div
      className={
      clsx(
        classes.root,
        `${ctaType === 'link' ? classes.ctaLinkType : classes.ctaButtonType}`,
        `${outlineCardContent ? classes.outlineCardContent : ''}`,
        `${centerCardContent ? classes.centerCardContent : ''}`,
        `${disabledCard ? classes.disabledCard : ''}`,
        'ctaWithIconCard',
      )
      }
      id={id}
    >
      <Grid
        container
        alignItems="center"
        className={`ctaHeaderGrid ${icon}HeaderGrid`}
        spacing={1}
        wrap="nowrap"
      >
        <Grid item aria-hidden="true">{iconDisplay}</Grid>
        <Grid item>
          <Typography variant={heading} className={classes.header}>
            {header}
          </Typography>
        </Grid>
      </Grid>
      <div className={iconIdent ? 'iconIdent' : null}>
        {paragraph ? (
          <Typography className={clsx(classes.paragraph, 'ctaParagraph')}>
            {paragraph}
          </Typography>
        ) : null}
        {listItems ? (
          <ul className="unordered-list main">
            {listItems.map((item, index) => (
              <li key={index}><span>{item}</span></li>
            ))}
          </ul>
        ) : null}
        {children}
        {ctaLabel ? (
          <div className={classes.cta}>
            {(ctaType === 'link' && to === '') ? (
              <LinkLongArrow
                color={linkColor}
                ariaLabel={ariaLabel}
                label={ctaLabel}
                hrefLink={hrefLink !== null ? hrefLink : null}
                handleClick={() => {
                  handleClick();
                }}
                disabled={disabledCard}
              />
            ) : null}
            {(ctaType === 'link' && to !== '') ? (
              <LinkLongArrow
                color={linkColor}
                ariaLabel={ariaLabel}
                label={ctaLabel}
                hrefLink={hrefLink !== null ? hrefLink : null}
                handleClick={() => {
                  handleClick();
                }}
                to={to}
                disabled={disabledCard}
              />
            ) : null}
            {(ctaType === 'button') ? (
              <Button
                className={`mainBtn ${buttonColor === 'blue' ? 'colorOverBtn' : ''}`}
                color={buttonType}
                ariaLabel={ariaLabel}
                disableRipple
                disableFocusRipple
                onClick={() => {
                  handleClick();
                }}
                disabled={disabledCard || disabled}
                role={ctaRoleType}
              >
                {disabled && <CircularProgress size={18} className={classes.circularProgress} />}
                {ctaLabel}
              </Button>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
}

CtaWithIconCard.defaultProps = {
  id: '',
  heading: 'h3',
  icon: 'documents',
  iconIdent: false,
  header: 'Add the header prop, please',
  listItems: null,
  paragraph: null,
  ctaLabel: null,
  ariaLabel: '',
  ctaType: 'link',
  ctaRoleType: 'button',
  linkColor: 'primary',
  buttonColor: 'red',
  buttonType: 'primary',
  hrefLink: null,
  to: '',
  handleClick: () => {},
  disabledCard: false,
  outlineCardContent: false,
  centerCardContent: false,
  children: null,
  disabled: false,
};

CtaWithIconCard.propTypes = {
  id: PropTypes.string,
  heading: PropTypes.string,
  icon: PropTypes.oneOf(['documents', 'laptop', 'list', 'certificateAward', 'error']),
  iconIdent: PropTypes.bool,
  header: PropTypes.string,
  listItems: PropTypes.arrayOf(PropTypes.string),
  paragraph: PropTypes.string,
  ctaLabel: PropTypes.string,
  ariaLabel: PropTypes.string,
  ctaType: PropTypes.oneOf(['link', 'button']),
  ctaRoleType: PropTypes.oneOf(['link', 'button']),
  linkColor: PropTypes.oneOf(['primary', 'secondary']),
  buttonColor: PropTypes.oneOf(['red', 'blue']),
  buttonType: PropTypes.oneOf(['primary', 'secondary']),
  hrefLink: PropTypes.string,
  to: PropTypes.string,
  handleClick: PropTypes.func,
  disabledCard: PropTypes.bool,
  outlineCardContent: PropTypes.bool,
  centerCardContent: PropTypes.bool,
  children: PropTypes.element,
  disabled: PropTypes.bool,
};
