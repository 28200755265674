import retryCallIfNeeded from '../../../restCalls';

export default function SalesforceService(client) {
  const getERInformation = async opportunityId => {
    const response = await retryCallIfNeeded(
      client,
      () => client.get(`/srm?opportunityId=${opportunityId}`),
    );
    const data = response?.data;
    return data;
  };

  const getApplicationData = async applywebAppId => {
    const response = await retryCallIfNeeded(
      client,
      () => client.get(`/srm/application?applywebAppId=${applywebAppId}`),
    );
    const data = response?.data;
    return data;
  };

  return { getERInformation, getApplicationData };
}
