import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    margin: 0,
    padding: 0,
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
    },
    '&.disabled': {
      '& .secondaryHeader': {
        color: '#5e7079!important',
      },
      '& .ctaParagraph': {
        color: '#5e7079!important',
      },
    },
  },
  mainHeader: {
    textAlign: 'left',
  },
  imageCTA: {
    marginBottom: theme.spacing(1),
  },
  studentLife: {
    textAlign: 'center',
    '& svg': {
      width: theme.spacing(18.3),
      height: 'auto',
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(24),
        height: 'auto',
      },
    },
  },
  studentPortal: {
    textAlign: 'center',
    '& svg': {
      width: theme.spacing(18.3),
      height: 'auto',
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(19),
        height: 'auto',
      },
    },
  },
  header: {
    fontSize: '1.6rem!important',
    fontWeight: '700!important',
    marginBottom: '1.6rem!important',
    [theme.breakpoints.up('sm')]: {
      fontSize: '2rem!important',
    },
  },
  paragraph: {
    marginBottom: theme.spacing(2),
  },
  cta: {
    marginBottom: theme.spacing(1),
    '& .mainBtn': {
      [theme.breakpoints.up('sm')]: {
        width: '100%!important',
        maxWidth: '100%!important',
      },
    },
  },
}));
